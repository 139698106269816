import React, { useState } from "react";
import "./Footer.css"; // External CSS file
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNewsletter } from "../../hooks/useNewsletter";
import { CustomButton } from "../CustomButton";

const Footer: React.FC = () => {
  const newsletter = useNewsletter();
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // Tracks if the button was clicked
  const [isSent, setIsSent] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
    return emailRegex.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (isSubmitted) {
      // Revalidate email if user starts typing after the button was clicked
      setIsSubmitted(false);
    }
    if (isSent) {
      setIsSent(false);
    }
  };

  const handleNewsletterSubmit = () => {
    setIsSubmitted(true); // Mark the form as submitted
    if (validateEmail(email)) {
      newsletter.handlePutNewsletter({ mail: email }); // Call the newsletter function with the valid email
      setIsSent(true);
      setEmail("");
    }
  };
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3 style={{ letterSpacing: "8px" }}>
          {t("FOOTER.LINKS").toUpperCase()}
        </h3>
        <ul>
          <li>
            <a href="/terms-of-use">{t("FOOTER.TERMS")}</a>
          </li>

          <li>
            <a href="/privacy-policy">{t("FOOTER.PRIVACY")}</a>
          </li>
          <li>
            <a href="/privacy-policy">{t("FOOTER.COOKIE")}</a>
          </li>
        </ul>
      </div>

      <div className="footer-section" style={{ textAlign: "center" }}>
        <h3 style={{ letterSpacing: "8px" }}>
          {t("FOOTER.NEWSLETTER").toUpperCase()}
        </h3>
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent form submission from refreshing the page
            handleNewsletterSubmit();
          }}
          style={{ display: "inline-block" }}
        >
          <input
            type="email"
            placeholder="Your Email Address"
            className="newsletter-input"
            style={{
              width: "315px",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginBottom: "0px",
            }}
            value={email}
            onChange={handleInputChange} // Update email value
          />

          <div
            style={{
              minHeight: "15px", // Reserve space for the error message
              marginBottom: "6px",
              marginTop: "6px",
              fontSize: "12px",
              color:
                isSubmitted && !isSent && !validateEmail(email)
                  ? "red"
                  : isSent && newsletter.isSuccess
                    ? "green"
                    : "transparent",
              transition: "color 0.3s",
              alignContent: "center",
            }}
          >
            {isSubmitted &&
              !isSent &&
              !validateEmail(email) &&
              "Please enter a valid email."}
            {isSent && "Subscribed successfully"}
          </div>

          <CustomButton
            text={t("FOOTER.SUBSCRIBE_NOW")}
            onClick={handleNewsletterSubmit}
            isLoading={newsletter.isLoading}
            sx={{ borderRadius: "24px", width: "170px", padding: "10px 20px" }}
          />
        </form>
      </div>

      <div className="footer-section">
        <h3 style={{ letterSpacing: "8px" }}>
          {t("FOOTER.CONTACT").toUpperCase()}
        </h3>
        <p>
          contact@byteopia-it.com
          <br />
          Building A1, Dubai Digital Park
          <br />
          Dubai Silicon Oasis | Dubai, UAE
        </p>
        <div className="social-icons">
          <a href="#">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
