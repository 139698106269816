import { ENDPOINTS } from "../endpoints";
import { CycleType, SubscriptionType } from "./userSubscriptionService";

export interface NewsletterParams {
  mail: string;
}

// Adjusted to accept headers
export const createNewsletter = async (
  { mail }: NewsletterParams,
  headers: { Authorization: string }
): Promise<Response> => {
  const endpoint = ENDPOINTS.NEWSLETTER;

  const payload = {
    email_address: mail,
  };
  try {
    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Failed to get newsletter");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error getting newsletter:", error);
    throw error;
  }
};
