import { useState } from "react";
import { getJWT } from "../utils/getJWT";
import { getPaymentHistory } from "../api/services/paymentHistoryService";
import { GetPaymentHistoryResponse } from "../api/services/paymentHistoryService";

export const usePaymentHistory = () => {
  const [paymentHistoryResult, setPaymentHistoryResult] =
    useState<GetPaymentHistoryResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handlePaymentHistory = async () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const token = await getJWT();

      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Pass headers to the API call and get the response
      const response: GetPaymentHistoryResponse =
        await getPaymentHistory(headers);

      // Set success state and store paymentHistory_id from the response
      setIsSuccess(true);
      setPaymentHistoryResult(response);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handlePaymentHistory,
    paymentHistoryResult,
    isLoading,
    isError,
    isSuccess,
  };
};
