import { ENDPOINTS } from "../endpoints";

// Adjusted to accept headers
export const cancelPayment = async (headers: {
  Authorization: string;
}): Promise<Response> => {
  const endpoint = ENDPOINTS.CANCEL_SUBSCRIPTION;

  try {
    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to delete payment");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error deletting payment:", error);
    throw error;
  }
};
