import React, { useEffect, useState, useRef } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QUERY_PARAMS } from "../../constants";
import { useGetOrderStatus } from "../../hooks/useGetOrderStatus";
import { useGetOrderResult } from "../../hooks/useGetOrderResult";
import { PhotoDisplay } from "./PhotoDisplay";
import { Loader } from "../Loader";
import { customColors } from "../../theme";
import { ErrorComponent } from "../ErrorComponent";
import { useCurrentImage } from "../../context/CurrentImageContext";
import { usePostOrderGenerateMore } from "../../hooks/usePostOrderGenerateMore";
import { GetOrderResultResponse } from "../../api/services/orderResultService";

const POLL_INTERVAL = 5000; // Polling every 5 seconds for initial orderId status
const MORE_IMAGES_POLL_INTERVAL = 10000; // Polling every 10 seconds for additional images

interface Props {
  orderIdProp?: string;
}

const PhotoPage: React.FC<Props> = ({ orderIdProp }) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const orderId = orderIdProp || searchParams.get(QUERY_PARAMS.order) || "";

  const statusAPI = useGetOrderStatus();
  const orderResultAPI = useGetOrderResult();

  const [morePicturesRequestedInDisplay, setMorePicturesRequestedInDisplay] =
    useState<boolean>(false);
  const [orderStatusRequested, setOrderStatusRequested] =
    useState<boolean>(false);

  const [orderPollStarted, setOrderPollStarted] = useState<boolean>(false);
  const [orderResult, setOrderResult] = useState<
    GetOrderResultResponse | undefined
  >();

  // Polling function for initial orderId status
  const pollOrderStatus = () => {
    statusAPI.handleGetOrderStatus({ orderId: orderId });
  };

  // Polling function for additional images
  const pollOrderResult = () => {
    orderResultAPI.handleGetOrderResult({ orderId: orderId });
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return minutes > 0
      ? `${minutes} minute${minutes > 1 ? "s" : ""} ${remainingSeconds} second${remainingSeconds !== 1 ? "s" : ""}`
      : `${seconds} second${seconds !== 1 ? "s" : ""}`;
  };

  const processingTimeText =
    statusAPI.orderStatus?.time_remaining &&
    statusAPI.orderStatus?.time_remaining > 0
      ? t("TEXT.PHOTOS.TIME_REMAINING", {
          time: formatTime(statusAPI.orderStatus.time_remaining),
        })
      : "";

  // Polling for initial orderId status and fetching results when ready
  useEffect(() => {
    if (
      !orderStatusRequested &&
      !statusAPI.orderStatus?.is_processed &&
      !statusAPI.isError
    ) {
      pollOrderStatus();
      setOrderStatusRequested(true);
    }

    const intervalId = setInterval(() => {
      if (!statusAPI.isError) pollOrderStatus();
      else clearInterval(intervalId);
    }, POLL_INTERVAL);

    if (statusAPI.orderStatus?.is_processed || statusAPI.isError) {
      clearInterval(intervalId);
      if (!statusAPI.isError)
        orderResultAPI.handleGetOrderResult({ orderId: orderId });
    }

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [orderStatusRequested, statusAPI.orderStatus, statusAPI.isError]);

  // Polling for more images when `morePicturesRequestedInDisplay` is true
  useEffect(() => {
    if (morePicturesRequestedInDisplay && !orderPollStarted) {
      pollOrderResult();
      setOrderPollStarted(true);
    }

    const intervalId = setInterval(() => {
      if (!orderResultAPI.isError) pollOrderResult();
      else clearInterval(intervalId);
    }, MORE_IMAGES_POLL_INTERVAL);

    if (orderResultAPI.isError || orderPollStarted === false) {
      clearInterval(intervalId);
      //clear
    }

    return () => clearInterval(intervalId); // Cleanup when component unmounts
  }, [morePicturesRequestedInDisplay, orderPollStarted]);

  // Effect to handle updates when new images are received
  useEffect(() => {
    if (!orderResult && orderResultAPI.isSuccess) {
      setOrderResult(orderResultAPI.orderResult);
    } else if (
      orderPollStarted &&
      orderResultAPI.isSuccess &&
      orderResultAPI.orderResult?.image
    ) {
      const currentImageSrcs = orderResultAPI.orderResult.image.map(
        (img) => img.image_src
      );

      const previousImageSrcs = orderResult
        ? orderResult?.image.map((img) => img.image_src)
        : [];

      // Check if there is at least one new image_src compared to previous
      const hasNewImageSrc =
        currentImageSrcs.length > previousImageSrcs.length ||
        !currentImageSrcs.every(
          (value, index) => value === previousImageSrcs[index]
        );

      // Update if there is a new image_src and stop polling if all image_srcs are filled
      if (hasNewImageSrc) {
        setOrderResult(orderResultAPI.orderResult);
      }

      // Stop polling if all images have a valid image_src
      const allImagesComplete = currentImageSrcs.every((src) => src !== "");
      if (allImagesComplete) {
        setMorePicturesRequestedInDisplay(false);
        setOrderPollStarted(false);
      }
    }
  }, [orderResultAPI.orderResult]);

  const renderLoadingState = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 6,
        // px: 3,
        // py: 4,
        p: { xs: "30px", md: "70px" },
        backgroundColor: customColors.semiDarkGray,
        borderRadius: "12px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: customColors.gold,
          mb: 3,
          fontFamily: "Roboto, sans-serif",
          letterSpacing: "0.5px",
        }}
      >
        {t("TEXT.PHOTOS.GOOD_THINGS_TAKE_TIME")}
      </Typography>

      {statusAPI.orderStatus?.percentage &&
      statusAPI.orderStatus?.percentage < 100 ? (
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            mb: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: "20px",
          }}
        >
          <LinearProgress
            sx={{
              flexGrow: 1,
              backgroundColor: customColors.mediumGray,
              borderRadius: "10px",
              position: "relative",
              overflow: "hidden",
              "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                width: "100%",
                background: `linear-gradient(90deg, transparent, ${"#fff"}, transparent)`,
                animation: "shimmer 1.5s infinite",
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: customColors.gold,
                transition: "width 0.5s ease-in-out",
              },
              height: "12px",
              "@keyframes shimmer": {
                "0%": { transform: "translateX(-100%)" },
                "100%": { transform: "translateX(100%)" },
              },
            }}
            value={statusAPI.orderStatus?.percentage}
            variant="determinate"
          />

          <Typography
            variant="h6"
            sx={{
              ml: 3,
              color: customColors.gold,
              fontWeight: "bold",
              fontSize: "1.25rem",
            }}
          >
            {t("TEXT.PHOTOS.PERCENTAGE_COMPLETE", {
              percentage: statusAPI.orderStatus?.percentage,
            })}
          </Typography>
        </Box>
      ) : (
        <Loader maxHeigthMobile="150px" maxHeigth="200px" />
      )}

      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          maxWidth: "600px",
          mb: 4,
          color: "white",
          fontFamily: "Roboto, sans-serif",
          lineHeight: "1.6",
          letterSpacing: "0.3px",
        }}
      >
        {t("TEXT.PHOTOS.PROCESSING_MESSAGE")}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mt: 2,
          fontStyle: "italic",
          color: "white",
          fontFamily: "Roboto, sans-serif",
        }}
      >
        {processingTimeText}{" "}
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignContent: "center",
        alignItems: "center",
        mb: "50px",
        // p: 2,
      }}
    >
      {orderResult ? (
        <PhotoDisplay
          orderId={orderId}
          result={orderResult}
          morePicturesRequested={morePicturesRequestedInDisplay}
          setMorePicturesRequested={setMorePicturesRequestedInDisplay}
        />
      ) : statusAPI.isError ? (
        <ErrorComponent />
      ) : (
        renderLoadingState()
      )}
    </Box>
  );
};

export default PhotoPage;
