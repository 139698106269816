import { useState } from "react";
import { getJWT } from "../utils/getJWT";
import { cancelPayment } from "../api/services/cancelPaymentService";

export const useCancelPayment = () => {
  const [result, setResult] = useState<number>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleCancelPayment = async () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      const token = await getJWT();

      // Check if the JWT token exists
      if (!token) {
        throw new Error("Missing JWT token");
      }

      // Create headers including the JWT token in the Authorization header
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Pass headers to the API call and get the response
      const result = await cancelPayment(headers);

      // Set success state and store payment_id from the response
      setIsSuccess(true);
      setResult(result.status);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleCancelPayment,
    result,
    isLoading,
    isError,
    isSuccess,
  };
};
