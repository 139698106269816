import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { customColors } from "../../theme";
import { Space, Style } from "../../types/api";
import { CustomButton } from "../CustomButton";
import { HelpOutline } from "@mui/icons-material";
import { OrderSetup } from "./types";
import { useFetchSpaces } from "../../hooks/useFetchSpaces";
import { useCreateOrder } from "../../hooks/useCreateOrder";
import { useNavigate } from "react-router-dom";
import { QUERY_PARAMS } from "../../constants";
import { Loader } from "../Loader";
import { useSubscription } from "../../context/SubscriptionContext";

interface Props {
  handleCreateOrder: (
    removeFurniture: boolean,
    spaceName: string,
    styleId: string,
    widgetId?: string
  ) => void;
  isDisabled: boolean;
  setIsError: (value: React.SetStateAction<boolean>) => void;
  actionText?: string;
  isLoading?: boolean;
  shouldHideFurnitureRemoval?: boolean;
}

export const FormPanel: React.FC<Props> = ({
  handleCreateOrder,
  isDisabled,
  setIsError,
  actionText,
  isLoading,
  shouldHideFurnitureRemoval = false,
}) => {
  const [orderSetup, setOrderSetup] = useState<OrderSetup>({
    photo: null,
    roomType: "",
    furnitureStyle: "",
    removeFurniture: false,
  });
  const spacesAPI = useFetchSpaces();

  const navigate = useNavigate();

  const [availableStyles, setAvailableStyles] = useState<Style[]>([]);

  const userSubscriptionAPI = useSubscription();

  const notEnougCredits: boolean =
    !userSubscriptionAPI.isLoading &&
    userSubscriptionAPI.subscriptionResult !== undefined &&
    userSubscriptionAPI.subscriptionResult.plan !== 0 &&
    userSubscriptionAPI.subscriptionResult?.credits_amount === 0;
  console.log(
    "SUBS CREDITS X",
    userSubscriptionAPI.isLoading,
    userSubscriptionAPI.subscriptionResult !== undefined,
    userSubscriptionAPI.subscriptionResult?.credits_amount,
    notEnougCredits
  );
  useEffect(() => {
    if (spacesAPI.isError) {
      setIsError(true);
    }
    if (spacesAPI.spaces && spacesAPI.spaces.length > 0) {
      const firstRoom = spacesAPI.spaces[0];
      setAvailableStyles(firstRoom.styles);
      let style = orderSetup.furnitureStyle;
      if (firstRoom.styles && firstRoom.styles.length > 0) {
        style = firstRoom.styles[0].id;
      }

      setOrderSetup({
        photo: orderSetup.photo,
        furnitureStyle: style,
        removeFurniture: orderSetup.removeFurniture,
        roomType: firstRoom.name,
      });
    }
  }, [spacesAPI.spaces]);

  const handleProcessPhoto = () => {
    const widget = (
      (spacesAPI.spaces || []).find(
        (space: Space) => space.name === orderSetup.roomType
      )?.styles || []
    ).find((styl: Style) => styl.id === orderSetup.furnitureStyle)?.widget_id;
    if (!isDisabled) {
      console.log("PROCESS PHOTO");
      handleCreateOrder(
        orderSetup.removeFurniture,
        orderSetup.roomType,
        orderSetup.furnitureStyle,
        widget || "65b4e95ec2b80c47dfe933ab"
      );
    }
  };

  const { t } = useTranslation();

  const handleRoomTypeChange = (roomTypeName: string) => {
    const selectedSpace = (spacesAPI.spaces || []).find(
      (space: Space) => space.name === roomTypeName
    );
    let selectedStyle = "";
    if (selectedSpace) {
      setAvailableStyles(selectedSpace.styles);
      if (selectedSpace.styles && selectedSpace.styles.length > 0) {
        selectedStyle = selectedSpace.styles[0].id;
      } else {
        selectedStyle = "";
      }
    }

    setOrderSetup({
      photo: orderSetup.photo,
      furnitureStyle: selectedStyle,
      removeFurniture: orderSetup.removeFurniture,
      roomType: roomTypeName,
    });
  };

  const furnitureRemovalField = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      <Checkbox
        checked={orderSetup.removeFurniture}
        onChange={(e) =>
          setOrderSetup({
            ...orderSetup,
            removeFurniture: e.target.checked,
          })
        }
        sx={{ color: "#FFF" }}
      />
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {t("TEXT.UPLOAD_PHOTO.REMOVE_FURNITURE")}
      </Typography>
      <Tooltip title={t("TEXT.UPLOAD_PHOTO.REMOVE_FURNITURE_INFO")} arrow>
        <IconButton size="small" sx={{ ml: 1 }}>
          <HelpOutline fontSize="small" sx={{ color: "#FFF" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const spaceStyleSelection = (
    <Box
      sx={{
        mb: 2,
        mt: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
        p: "15px",
        color: "#fff",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 600, color: "#fff", mb: "10px" }}
      >
        {t("TEXT.UPLOAD_PHOTO.ADD_FURNITURE")}
      </Typography>

      <InputLabel id="room-type-label" sx={{ color: "#fff" }} shrink>
        {t("TEXT.UPLOAD_PHOTO.ROOM_TYPE")}
      </InputLabel>
      <Select
        labelId="room-type-label"
        value={orderSetup.roomType}
        onChange={(e) => handleRoomTypeChange(e.target.value)}
        disabled={!spacesAPI.spaces || spacesAPI.spaces.length <= 0}
        sx={{
          color: "#fff",
          borderColor: "#fff",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc", // Softer border
          },
          width: "100%",
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            paddingTop: "6px",
            paddingBottom: "6px", // Adjust padding to reduce height
          },
          ".MuiSvgIcon-root": {
            color: "#fff" /* Arrow color */,
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "#333",
              color: "#fff",
            },
          },
        }}
      >
        {spacesAPI.spaces && spacesAPI.spaces.length > 0 ? (
          spacesAPI.spaces.map((space: Space) => (
            <MenuItem
              key={space.name}
              value={space.name}
              sx={{
                display: "flex",
                alignItems: "center",
                height: "40px", // Ensure the item height is consistent
              }}
            >
              <img
                src={space.icon_src}
                alt=""
                style={{
                  marginRight: "10px",
                  width: "20px",
                  height: "20px",
                  objectFit: "contain",
                }}
              />
              {space.title}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{t("TEXT.UPLOAD_PHOTO.NO_ROOM_TYPES")}</MenuItem>
        )}
      </Select>

      <InputLabel
        id="furniture-style-label"
        sx={{ color: "#fff", mt: "10px" }}
        shrink
      >
        {t("TEXT.UPLOAD_PHOTO.STYLE")}
      </InputLabel>
      <Select
        labelId="furniture-style-label"
        value={orderSetup.furnitureStyle}
        onChange={(e) =>
          setOrderSetup({ ...orderSetup, furnitureStyle: e.target.value })
        }
        disabled={!availableStyles || availableStyles.length <= 0}
        sx={{
          color: "#fff",
          borderColor: "#fff",
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc", // Softer border
          },
          width: "100%",
          ".MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            paddingTop: "6px",
            paddingBottom: "6px", // Adjust padding to reduce height
          },
          ".MuiSvgIcon-root": {
            color: "#fff" /* Arrow color */,
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: "#333", // Background of the dropdown
              color: "#fff", // Text color
            },
          },
        }}
      >
        {availableStyles.map((style: Style) => (
          <MenuItem
            key={style.id}
            value={style.id}
            sx={{
              display: "flex",
              alignItems: "center",
              height: "60px",
            }}
          >
            {style.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  const outOfPhotos = (
    <Box sx={{ p: "10px 20px" }}>
      <Typography
        variant="subtitle1"
        sx={{ color: "#fff", mb: "10px", textAlign: "center" }}
      >
        {t("TEXT.UPLOAD_PHOTO.NOT_ENOUGH_CREDITS")}
      </Typography>
      <CustomButton
        text={t("TEXT.UPLOAD_PHOTO.NOT_ENOUGH_CREDITS_BUTTON")}
        onClick={() => navigate("/pricing")}
      />
    </Box>
  );

  return notEnougCredits ? (
    outOfPhotos
  ) : (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {spacesAPI.isLoading || userSubscriptionAPI.isLoading ? (
        <Loader maxHeigth="250px" maxHeigthMobile="250px" />
      ) : (
        <Box>
          {!shouldHideFurnitureRemoval && furnitureRemovalField}
          {spaceStyleSelection}
        </Box>
      )}

      <Box mt={3}>
        <CustomButton
          text={actionText || t("TEXT.UPLOAD_PHOTO.PROCESS_PHOTO")}
          onClick={handleProcessPhoto}
          isDisabled={isDisabled}
          isLoading={isLoading || false}
        />
      </Box>

      <Typography
        sx={{
          color: customColors.silver,
          fontSize: "13px",
          textAlign: "center",
        }}
      >
        {"Unlimited variations and downloads included"}
      </Typography>
    </Box>
  );
};
