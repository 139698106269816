import { ENDPOINTS } from "../endpoints";

export enum SubscriptionType {
  NONE = 0,
  BASIC = 1,
  STANDARD = 2,
  PROFESSIONAL = 3,
}
export enum CycleType {
  MONTHLY = 1,
  YEARLY = 2,
}
export type GetUserSubscriptionResponse = {
  plan: SubscriptionType;
  cycle: CycleType;
  cost: number;
  next_payment_date: string;
  last_payment_date: string;
  used_credits_per_month: number;
  total_credits_per_month: number;
  credits_amount: number;
  canceled: boolean;
};

// const testResponse: GetUserSubscriptionResponse = {
//   plan: SubscriptionType.BASIC,
//   cycle: CycleType.YEARLY,
//   cost: 111,
//   next_payment_date: "2024-10-27T18:26:47",
//   last_payment_date: "2024-10-27T18:26:47",
//   used_credits_per_month: 2,
//   total_credits_per_month: 10,
//   credits_amount: 8,
//   canceled: false,
// };

export const getUserSubscription = async (headers: {
  Authorization: string;
}): Promise<GetUserSubscriptionResponse> => {
  try {
    const url = new URL(ENDPOINTS.SUBSCRIPTION);
    console.log("Fetching: ");

    const response = await fetch(url.toString(), {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error("Failed to get userSubscription");
    }

    // Parse the response JSON and cast it to PostOrderResponse
    const data = await response.json();

    // return data;
    return data;
  } catch (error) {
    console.error("Error getting userSubscription:", error);
    throw error;
  }
};
