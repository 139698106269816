import React from "react";
import { PaymentData } from "../../api/services/paymentHistoryService";
import { Loader } from "../Loader";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { customColors } from "../../theme";

export type GetPaymentHistoryResponse = Array<PaymentData>;

interface PaymentHistoryProps {
  payments?: GetPaymentHistoryResponse;
}

export const PaymentHistory: React.FC<PaymentHistoryProps> = ({ payments }) => {
  const { t } = useTranslation();
  return payments ? (
    <div>
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        {t("TEXT.SETTINGS.PAYMENT_HISTORY")}
      </Typography>

      <Divider sx={{ marginY: 2, backgroundColor: customColors.lightGray }} />

      {payments.map((payment, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
            borderBottom:
              index !== payments.length - 1 ? "1px solid #333" : "none",
          }}
        >
          <div>
            <h3 style={{ margin: "0", fontSize: "16px" }}>
              {payment.plan_name}
            </h3>
            <p style={{ margin: "0", fontSize: "14px", color: "#888" }}>
              {`${payment.credits_amount} Credits`}
            </p>
          </div>
          <div style={{ textAlign: "right" }}>
            <p style={{ margin: "0", fontSize: "16px" }}>
              ${payment.cost.toFixed(2)}
            </p>
            <p style={{ margin: "0", fontSize: "14px", color: "#888" }}>
              {new Date(payment.payment_date).toLocaleDateString()}
            </p>
            <a
              href={payment.invoice_uri}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: "12px",
                color: customColors.gold,
                textDecoration: "underline",
                display: "block",
                marginTop: "5px",
              }}
            >
              {t("TEXT.SETTINGS.VIEW_INVOICE")}
            </a>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <Loader />
  );
};

// // Example usage
// const examplePayments: GetPaymentHistoryResponse = [
//   {
//     plan: 1,
//     cycle: 1,
//     payment_date: "2024-11-08T20:45:31",
//     cost: 15.0,
//     invoice_uri: "https://invoice.stripe.com/example",
//     plan_name: "1 × Basic Plan (at $15.00 / month)",
//     credits_amount: 6,
//   },
//   {
//     plan: 2,
//     cycle: 1,
//     payment_date: "2024-10-08T20:45:31",
//     cost: 99.0,
//     invoice_uri: "https://invoice.stripe.com/example2",
//     plan_name: "1 × Enterprise Plan (at $99.00 / month)",
//     credits_amount: 200,
//   },
// ];
