import React, { useState } from "react";
import { useGetGallery } from "../../hooks/useGetGallery";
import { PhotoLibrary, Folder, Delete } from "@mui/icons-material";
import { Box, Typography, Button, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import { QUERY_PARAMS } from "../../constants";
import { Loader } from "../Loader";
import { useTranslation } from "react-i18next"; // Assuming you are using i18next for translations
import { PLACEHOLDER_IMAGE } from "../../constatnts";
import ChangingImage from "./ChangingImage";

const MyPhotos: React.FC = () => {
  const { t } = useTranslation();
  const [orderSent, setOrderSent] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 12;
  const galleryResponse = useGetGallery();
  const navigate = useNavigate();
  const params = new URLSearchParams();

  const totalPages = Math.ceil(
    (galleryResponse.gallery?.total_count || 0) / itemsPerPage
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    galleryResponse.handleGetGallery({ page: value, itemsPerPage });
  };

  if (!orderSent) {
    galleryResponse.handleGetGallery({ page: 1, itemsPerPage });
    setOrderSent(true);
  }

  return (
    <Box
      sx={{
        alignContent: "left",
        alignItems: "left",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxWidth: "1400px",
          margin: "0 auto",
          alignContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        {/* Main Content */}
        <Box sx={{ flexGrow: 1, padding: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 3,
            }}
          >
            <Typography variant="h5">
              {t("TEXT.PHOTOS.ALL_PHOTOS_HEADING")}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/upload-photo")}
            >
              {t("TEXT.PHOTOS.UPLOAD_PHOTO")}
            </Button>
          </Box>

          {galleryResponse.isLoading ? (
            <Loader />
          ) : (
            <Grid container spacing={2}>
              {galleryResponse.gallery &&
                galleryResponse.gallery.orders.map((order) => (
                  <Grid
                    size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
                    key={order.order_id}
                    sx={{
                      position: "relative",
                      borderRadius: "10px",
                      overflow: "hidden", // Ensures no content overflows the container
                      "&:hover": {
                        cursor: "pointer",
                        boxShadow: "0 0px 6px 4px rgba(235, 196, 127, 0.3)",
                      },
                      minHeight: "200px",
                    }}
                    onClick={() => {
                      params.append(QUERY_PARAMS.order, order.order_id);
                      navigate(`/photo?${params.toString()}`);
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "12px",
                        fontSize: "12px",
                        zIndex: 1, // Ensures this is displayed above the image
                      }}
                    >
                      {t("TEXT.PHOTOS.RESULTS_COUNT", {
                        count: order.images_count,
                      })}
                    </div>
                    <Box sx={{ height: "100%" }}>
                      <ChangingImage
                        beforeSrc={order.before_src}
                        afterSrc={order.after_src}
                      />
                    </Box>
                  </Grid>
                ))}
            </Grid>
          )}

          {/* Pagination Component */}
          {totalPages > 1 && (
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MyPhotos;
