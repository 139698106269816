import React, { useEffect } from "react";
import { CssBaseline, Container } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Gallery from "./components/Gallery/Gallery";
import Navbar from "./components/Home/Header/Nav";
import { customColors } from "./theme";
import { Login } from "./components/AuthComponents/Login";
import { Register } from "./components/AuthComponents/Register";
import Footer from "./components/Footer/Footer";
import PricingPlan from "./components/Pricing/PricingPage";
import MyPhotos from "./components/Photos/MyPhotos";
import Settings from "./components/Settings/Settings";
import UploadPhoto from "./components/Photos/UploadPhoto";
import { Checkout } from "./components/Checkout/Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TermsOfUse from "./components/Legal/TermsOfUse";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import { ForgotPassword } from "./components/AuthComponents/ForgotPassword";
import { CheckYourEmail } from "./components/AuthComponents/CheckYourEmail";
import { printJWT } from "./utils/getJWT";
import PhotoPage from "./components/Photos/PhotoPage";
import { CurrentImageProvider } from "./context/CurrentImageContext";
import { UpdatePaymentDetails } from "./components/Checkout/UpdatePaymentDetails";
import {
  SubscriptionProvider,
  useSubscription,
} from "./context/SubscriptionContext";
import { useAuth } from "./hooks/useAuth";
import { userHasSubscription } from "./utils/userType";
import PaymentStatus from "./components/Pricing/PaymentStatus";

const App: React.FC = () => {
  useEffect(() => {
    document.body.style.paddingTop = "env(safe-area-inset-top)";
    document.body.style.backgroundColor = "#yourBackgroundColor"; // Replace with your desired color
  }, []);

  const { user } = useAuth();

  const { subscriptionResult, isLoading, isError, refetchSubscription } =
    useSubscription();

  const stripePromise = loadStripe(
    "pk_test_51PyDmRRt2ts7ecPYv7dVbG52WrsSHcKtkWguAoYGefGJdWExUWH6tA6nUMmXV60cYVeozvcTXTiuYGrnjnKc50px00bTDsoCaL"
  );

  const isUserWithSubscription =
    user &&
    user.emailVerified &&
    subscriptionResult &&
    userHasSubscription(subscriptionResult);

  return (
    <>
      <Elements stripe={stripePromise}>
        <CssBaseline />
        <CurrentImageProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              backgroundColor: customColors.darkGray,
            }}
          >
            <Router>
              <Navbar />
              <Container
                disableGutters
                maxWidth={false}
                sx={{ flex: 1, padding: 0, margin: 0 }}
              >
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/gallery" element={<Gallery />} />
                  <Route path="/pricing" element={<PricingPlan />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Register />} />
                  <Route
                    path="/check-your-email"
                    element={<CheckYourEmail />}
                  />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/myphotos"
                    element={
                      user && user.emailVerified ? <MyPhotos /> : <Home />
                    }
                  />
                  <Route
                    path="/settings"
                    element={isUserWithSubscription ? <Settings /> : <Home />}
                  />
                  <Route path="/upload-photo" element={<UploadPhoto />} />
                  <Route path="/terms-of-use" element={<TermsOfUse />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/photo" element={<PhotoPage />} />
                  <Route
                    path="/update-payment"
                    element={<UpdatePaymentDetails />}
                  />
                  <Route
                    path="/payment-status/:status"
                    element={<PaymentStatus />}
                  />
                </Routes>
              </Container>
            </Router>
            <Footer />
          </div>
        </CurrentImageProvider>
      </Elements>
    </>
  );
};

export default App;
